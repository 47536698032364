<template>
  <div id="conteudo">
    <Titulo texto="Galvanoplastia" opacityTitle="Serviços" :servico="true" />
    <cardServicos
      v-for="(conteudo, index) in conteudoCards"
      :key="index"
      :titulo="conteudo.titulo"
      :image="conteudo.imagem"
      :alt="conteudo.alt"
    >
      {{ conteudo.texto }}
    </cardServicos>
  </div>
</template>

<script>
import cardServicos from "../../components/cardServicos.vue";
import Titulo from "../../components/Titulo.vue";

export default {
  components: { cardServicos, Titulo },
  data() {
    return {
      conteudoCards: [
        {
          titulo: "Galvanoplastia para Zinco",
          texto:
            "Processo químico de tratamento de superfície que atribui resistência à peça, já que, uma fina camada de zinco protege seu exterior. Tal processo confere a peça certa coloração, podendo ser branco, amarelo ou preto, o que permite que seja utilizada nos mais variados setores. ",
          imagem: "galvanoplastia/galvanoplastia1.webp",
          alt: "Galvanoplastia para Zinco",
        },
        {
          titulo: "Cromo decorativo",
          texto:
            "Processo de tratamento de superfície que atribui diversas qualidades ao produto. Com finalidade estética e decorativa, atribui ao produto característica brilhante, além de torna-lo mais resistente à corrosão. ",
          imagem: "galvanoplastia/galvanoplastia2.webp",
          alt: "Cromo decorativo",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.estrutura {
  color: #ededed;
  h3 {
    font-family: Monument Extended, sans-serif;
    font-size: 2.25rem;
  }

  ul {
    font-size: 1.25rem;
    color: #05c8ef;
    padding-inline-start: 20px;

    li {
      p {
        color: #ededed;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .estrutura {
    h3 {
      font-size: 1.6rem;
    }

    ul {
      font-size: 1rem;
    }
  }
}
</style>